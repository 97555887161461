<template>
  <div>
    <my-header></my-header>
    <div class="table-box">
      <div><span>公司信息</span></div>
      <div class="detail">
        <div>
          <span>公司图标:</span> <img :src="Myaudit.companyLogo" alt="" />
        </div>
        <div>
          <span>公司名称:</span><span v-text="Myaudit.companyName"></span>
        </div>

        <div>
          <span>营业执照:</span
          ><img class="licenseImg" :src="Myaudit.licenseImg" alt="" />
        </div>
        <div>
          <span>营业执照注册号:</span
          ><span v-text="Myaudit.licenseNumber"></span>
        </div>
        <div><span>详细地址:</span><span v-text="Myaudit.address"></span></div>
        <div><span>法人姓名:</span><span v-text="Myaudit.person"></span></div>
        <div>
          <span>法人电话:</span><span v-text="Myaudit.personNumber"></span>
        </div>
        <div>
          <span>公司电话:</span><span v-text="Myaudit.companyPhone"></span>
        </div>
        <div>
          <span>银行开户许可证:</span
          ><span v-text="Myaudit.organizationCode"></span>
        </div>
        <div>
          <span>组织结构代码证:</span><span v-text="Myaudit.companyName"></span>
        </div>
        <div><span>银行卡号:</span><span v-text="Myaudit.bankId"></span></div>
        <!-- <div><span>备注:</span><span v-text="Myaudit.message"></span></div> -->
        <div>
          <span>公司类型:</span
          ><span
            v-text="
              Myaudit.type == 1
                ? '厂商'
                : Myaudit.type == 2
                ? '客户'
                : Myaudit.type == 3
                ? '经销商'
                : Myaudit.type == 4
                ? '餐饮公司'
                : '未知'
            "
          ></span>
        </div>
        <div>
          <span>公司状态:</span
          ><span v-text="Myaudit.status == 0 ? '正常' : '锁定'"></span>
        </div>
        <div>
          <span>所在地区:</span
          ><span v-text="Myaudit.areaName ? Myaudit.areaName : '未填写'"></span>
        </div>
        <div>
          <span>所在地区:</span
          ><span
            v-text="
              Myaudit.areaName
                ? `${Myaudit.areaName[0]}${Myaudit.areaName[1]}${Myaudit.areaName[2]}`
                : '未填写'
            "
          ></span>
        </div>
        <div>
          <span>创建时间:</span><span v-text="Myaudit.createTime"></span>
        </div>
        <div>
          <span>修改时间:</span><span v-text="Myaudit.updateTime"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/my-header.vue";

export default {
  data() {
    return {
      Myaudit: {},
    };
  },
  methods: {
    async getcompanyInfo() {
      const res = await this.$http({
        url: `front/company/findList?companyUserId=${sessionStorage.getItem(
          "userId"
        )}`,
      });
      this.Myaudit = res.data.data.list[0];
      console.log(this.Myaudit);
    },
  },
  created() {
    this.getcompanyInfo();
  },
};
</script>

<style lang="scss" scoped>
.table-box {
  margin-top: 140px;
  & > div:nth-child(1) {
    background: #f5f5f6;
    height: 150px;
    margin: 0 auto;
    font-size: 36px;
    color: #101d37;
    font-weight: 700;
    text-align: center;
    line-height: 120px;
    position: relative;
    color: #101d37 !important;
  }
  .detail {
    width: 100%;
    color: #666;
    border: 1px solid #ccc;
    div {
      padding: 5px;
      .licenseImg {
        width: 400px;
        margin-left: 50px;
      }
    }
    div:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
    div > span:nth-child(1) {
      display: inline-block;
      text-align: right;
      width: 35%;
    }
    div > span:nth-child(2) {
      margin-left: 50px;
    }
    .file {
      text-align: center;
      .downLoadUrl {
        color: #409eff;
        cursor: pointer;
      }
    }
  }
}
</style>