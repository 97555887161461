var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('my-header'),_c('div',{staticClass:"table-box"},[_vm._m(0),_c('div',{staticClass:"detail"},[_c('div',[_c('span',[_vm._v("公司图标:")]),_vm._v(" "),_c('img',{attrs:{"src":_vm.Myaudit.companyLogo,"alt":""}})]),_c('div',[_c('span',[_vm._v("公司名称:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.companyName)}})]),_c('div',[_c('span',[_vm._v("营业执照:")]),_c('img',{staticClass:"licenseImg",attrs:{"src":_vm.Myaudit.licenseImg,"alt":""}})]),_c('div',[_c('span',[_vm._v("营业执照注册号:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.licenseNumber)}})]),_c('div',[_c('span',[_vm._v("详细地址:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.address)}})]),_c('div',[_c('span',[_vm._v("法人姓名:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.person)}})]),_c('div',[_c('span',[_vm._v("法人电话:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.personNumber)}})]),_c('div',[_c('span',[_vm._v("公司电话:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.companyPhone)}})]),_c('div',[_c('span',[_vm._v("银行开户许可证:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.organizationCode)}})]),_c('div',[_c('span',[_vm._v("组织结构代码证:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.companyName)}})]),_c('div',[_c('span',[_vm._v("银行卡号:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.bankId)}})]),_c('div',[_c('span',[_vm._v("公司类型:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.Myaudit.type == 1
              ? '厂商'
              : _vm.Myaudit.type == 2
              ? '客户'
              : _vm.Myaudit.type == 3
              ? '经销商'
              : _vm.Myaudit.type == 4
              ? '餐饮公司'
              : '未知'
          )}})]),_c('div',[_c('span',[_vm._v("公司状态:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.status == 0 ? '正常' : '锁定')}})]),_c('div',[_c('span',[_vm._v("所在地区:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.areaName ? _vm.Myaudit.areaName : '未填写')}})]),_c('div',[_c('span',[_vm._v("所在地区:")]),_c('span',{domProps:{"textContent":_vm._s(
            _vm.Myaudit.areaName
              ? ("" + (_vm.Myaudit.areaName[0]) + (_vm.Myaudit.areaName[1]) + (_vm.Myaudit.areaName[2]))
              : '未填写'
          )}})]),_c('div',[_c('span',[_vm._v("创建时间:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.createTime)}})]),_c('div',[_c('span',[_vm._v("修改时间:")]),_c('span',{domProps:{"textContent":_vm._s(_vm.Myaudit.updateTime)}})])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_vm._v("公司信息")])])}]

export { render, staticRenderFns }